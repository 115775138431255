/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const NinethSection = () => {

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn
            id={'specialists'}
            css={[tw`px-[1rem] bg-pageBgWhite items-center justify-center py-[6rem] w-full`,
            css`
              @media (min-width: 744px) and (max-width: 1280px) {
                ${tw`px-[4.5rem] py-[2.5rem]`}
              }
              @media (min-width: 280px) and (max-width: 744px) {
                ${tw`px-[1rem] py-[1.25rem]`}
              }
            `]}>
            <FlexRowBetween
                css={[
                    tw`max-w-medium justify-center items-center space-x-[9rem]`,
                    css`
                      @media (min-width: 280px) and (max-width: 1280px) {
                        ${tw`flex-col-reverse items-start space-x-0 w-full`}
                      }
                    `]}
            >
                <img css={[tw`self-center`, css`
                  @media (min-width: 744px) and (max-width: 1280px) {
                    ${tw`py-[1.88rem]`}
                  }
                  @media (min-width: 280px) and (max-width: 744px) {
                    ${tw`hidden`}
                  }
                `]} src={i18n.language === 'en' ? '/images/6en.png' : '/images/ninethBanner.svg'}/>

                <FlexColumnStart css={[tw`space-y-[2.69rem]`, css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`space-y-[1.25rem]`}
                  }
                `]}>
                    <FlexColumn
                        tw={'bg-primary p-4 rounded-full w-[3.125rem] h-[3.125rem] items-center justify-center'}>
                        <img
                            css={[css`
                              filter: brightness(0) invert(1);
                            `]}
                            src={'/images/specialists.svg'}/>
                    </FlexColumn>
                    <StyledText size={'head'} tw={'font-[656] text-[2.8125rem] leading-[3.85rem]'}>
                        {t("nineth.title")}
                    </StyledText>
                    <FlexColumnStart
                        css={[
                            tw`space-y-[1.25rem] w-[24.25rem] font-[457]`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`space-y-[0.625rem] w-full`}
                              }
                            `
                        ]}
                    >
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("nineth.desc")}
                        </StyledText>
                        <img css={[tw`hidden self-center`, css`
                          @media (min-width: 744px) and (max-width: 1280px) {
                            ${tw`py-[1.88rem]`}
                          }
                          @media (min-width: 280px) and (max-width: 480px) {
                            ${tw`block`}
                          }
                        `]} src={i18n.language === 'en' ? '/images/6en.png' : '/images/ninethBanner.svg'}/>
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("nineth.desc1")}
                        </StyledText>
                    </FlexColumnStart>

                    <StyledButton
                        onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                        css={[tw`px-[3.125rem] text-[1rem] font-[457] flex-row flex space-x-[0.5rem] justify-center items-center`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`hidden`}
                              }
                              @media (max-width: 480px) {
                                ${tw`w-full`}
                              }
                            `]}
                    >
                        <StyledText tw={'text-[1rem] text-white'}>
                            {t("menu.join")}
                        </StyledText>
                        <img
                            css={[css`
                              filter: brightness(0) invert(1);
                              width: 1.375rem;
                              height: 1.375rem;
                            `]}
                            src={'/images/users.svg'}/>
                    </StyledButton>
                </FlexColumnStart>
            </FlexRowBetween>

            <StyledButton
                onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                css={[tw`hidden px-[1rem] text-[1rem] font-[457] flex-row flex space-x-[0.5rem] justify-center items-center`,
                    css`
                      @media (min-width: 280px) and (max-width: 1280px) {
                        ${tw`flex mt-[1.88rem]`}
                      }
                      @media (max-width: 480px) {
                        ${tw`w-full`}
                      }
                    `]}
            >
                <StyledText tw={'text-[1rem] text-white'}>
                    {t("menu.join")}
                </StyledText>
                <img
                    css={[css`
                      filter: brightness(0) invert(1);
                      width: 1.375rem;
                      height: 1.375rem;
                    `]}
                    src={'/images/users.svg'}/>
            </StyledButton>
        </FlexColumn>
    );
};
