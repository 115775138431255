/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const SecondSection = () => {

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn css={[tw`px-[1rem] bg-pageBgWhite items-center justify-center py-[6rem] w-full`,
            css`
              @media (min-width: 744px) and (max-width: 1280px) {
                ${tw`py-[2.5rem] px-[4.5rem]`}
              }
              @media (min-width: 280px) and (max-width: 744px) {
                ${tw`py-[1.25rem] px-[1rem]`}
              }
            `
        ]}>
            <FlexRowBetween css={[tw`max-w-medium justify-center items-center space-x-[9rem]`, css`
              @media (min-width: 280px) and (max-width: 1280px) {
                ${tw`flex-col items-start space-x-[0px] w-full`}
              }
            `]}>
                <FlexColumnStart css={[tw`space-y-[2.69rem]`, css`
                  @media (min-width: 280px) and (max-width: 744px) {
                    ${tw`space-y-[1.25rem]`}
                  }
                `]}>
                    <StyledText size={'head'} tw={'font-[656] text-[2.8125rem] leading-[3.85rem]'}>
                        {t("second.alterego")}
                    </StyledText>
                    <FlexColumnStart
                        css={[
                            tw`space-y-[1.25rem] w-[24.25rem] font-[457]`,
                            css`
                              @media (max-width: 480px) {
                                ${tw`w-full space-y-[0.625rem]`}
                              }
                            `
                        ]}
                    >
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("second.desc")}
                        </StyledText>
                        <img css={[css`
                          display: none;
                          @media (max-width: 480px) {
                            ${tw`m-0 block`}
                          }
                        `]} src={i18n.language === 'en' ? '/images/1en.png' : '/images/banner_sec.svg'}/>
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("second.desc1")}
                        </StyledText>
                    </FlexColumnStart>

                    <StyledButton
                        onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                        css={[tw`px-[3.125rem] text-[1rem] font-[457]`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`hidden`}
                              }
                              @media (max-width: 480px) {
                                ${tw`w-full text-[0.7rem]`}
                              }
                            `]}
                    >
                        {t("second.createAlter")}
                    </StyledButton>
                </FlexColumnStart>
                <img css={[css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`my-[1.88rem] self-center`}
                  }
                  @media (max-width: 480px) {
                    ${tw`m-0 hidden`}
                  }
                `]} src={i18n.language === 'en' ? '/images/1en.png' : '/images/banner_sec.svg'}/>

                <StyledButton
                    onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                    css={[tw`hidden px-[3.125rem] text-[1rem] font-[457]`,
                        css`
                          @media (min-width: 280px) and (max-width: 1280px) {
                            ${tw`block self-center mt-[1rem]`}
                          }
                          @media (min-width: 280px) and (max-width: 480px) {
                            ${tw`w-full px-[2.5rem]`}
                          }
                        `]}
                >
                    {t("second.createAlter")}
                </StyledButton>
            </FlexRowBetween>
        </FlexColumn>
    );
};
