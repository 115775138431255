/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {
    FlexColumn,
    FlexColumnStart,
    FlexRow,
    FlexRowBetween,
    StyledButton,
    StyledText
} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useState} from "react";
import {useTranslation} from "react-i18next";

export const FAQ = () => {
    const {t, i18n} = useTranslation();

    const items = [
        {
            title: `FAQ.first_title`,
            text: `FAQ.first_desc`
        },
        {
            title: `FAQ.sec_title`,
            text: `FAQ.sec_desc`
        },
        {
            title: `FAQ.3.title`,
            text: `FAQ.3.text`
        },
        {
            title: `FAQ.4.title`,
            text: `FAQ.4.text`
        },
        {
            title: `FAQ.5.title`,
            text: `FAQ.5.text`
        },
        {
            title: `FAQ.6.title`,
            text: `FAQ.6.text`
        },
    ];

    const [openItem, setOpenItem] = useState<string | null>(null);

    return (
        <FlexColumn css={[tw`px-[1rem] w-full bg-white py-[6rem] items-center justify-center`, css`
          @media (min-width: 744px) and (max-width: 1280px) {
            ${tw`px-[4.5rem] py-[2.5rem]`}
          }
          @media (min-width: 280px) and (max-width: 744px) {
            ${tw`px-[1rem] py-[1.25rem]`}
          }

        `
        ]}>
            <FlexColumn css={[tw`max-w-medium justify-center bg-white items-center`, css`
              @media (min-width: 280px) and (max-width: 1280px) {
                ${tw`items-start`}
              }
            `]}>
                <FlexColumn css={[tw`space-y-[0.5rem] items-center`, css`
                  @media (max-width: 1280px) and (min-width: 280px) {
                    ${tw`items-start`}
                  }
                `]}>
                    <StyledText size={'big'} tw={'font-[600] p-0 m-0'}>
                        {t("FAQ.title")}
                    </StyledText>
                    <StyledText size={'small'} tw={'text-grayLight font-[457] p-0 m-0'}>
                        {t("FAQ.desc")}
                    </StyledText>
                </FlexColumn>
                <FlexRow
                    css={[
                        tw`flex-wrap gap-[1.88rem] mt-[3.75rem] justify-center`,
                        css`
                          @media (min-width: 280px) and (max-width: 1280px) {
                            ${tw`flex-col items-start`}
                          }
                        `
                    ]}
                >
                    {items.map((it, idx) =>
                        <FlexRow
                            css={[
                                tw`w-[35.25rem] space-x-[0.75rem] p-[0.94rem]`,
                                css`
                                  @media (min-width: 280px) and (max-width: 1280px) {
                                    ${tw`w-full items-start p-0`}
                                  }
                                `
                            ]}>
                            <img
                                css={[tw`w-[1.5rem] h-[1.5rem]`,
                                    css`
                                      @media (min-width: 744px) and (max-width: 1280px) {
                                        ${tw`hidden`}
                                      }
                                    `]}
                                src={'/images/question.svg'}/>
                            <FlexColumnStart
                                tw={'w-full space-y-[0.98rem]'}>

                                <FlexRowBetween tw={'w-full'}>
                                    <StyledText
                                        size={'small'}
                                        css={[
                                            tw`font-[556]`,
                                            css`
                                              @media (min-width: 280px) and (max-width: 1280px) {
                                                ${tw`w-3/4`}
                                              }
                                            `
                                        ]}
                                    >
                                        {t(it.title)}
                                    </StyledText>
                                    <StyledButton
                                        onClick={() => {
                                            setOpenItem(it.title === openItem ? null : it.title)
                                        }}
                                        css={[tw`w-[1.5rem] h-[1.5rem] p-[5px]`,
                                            css`
                                              display: none;
                                              @media (min-width: 280px) and (max-width: 1280px) {
                                                display: block;
                                              }
                                            `
                                        ]}
                                    >
                                        <img
                                            css={[
                                                tw`w-[0.9rem] h-[0.9rem]`,
                                                openItem === it.title ? tw`rotate-90` : tw`-rotate-90`
                                            ]}
                                            src={'/images/shevron.svg'}/>
                                    </StyledButton>
                                </FlexRowBetween>
                                <StyledText
                                    css={[
                                        tw`text-[1rem] font-[457] text-textGray`,
                                        css`
                                          @media (min-width: 280px) and (max-width: 1280px) {
                                            display: none;
                                            ${openItem === it.title && tw`block`}
                                          }
                                        `
                                    ]}
                                >
                                    {t(it.text)}
                                </StyledText>
                            </FlexColumnStart>
                        </FlexRow>
                    )}

                </FlexRow>

            </FlexColumn>
        </FlexColumn>
    );
};
