/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const FifthSection = () => {

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn
            id={'community'}
            css={[tw`px-[1rem] bg-pageBgWhite items-center justify-center py-[6rem] w-full`, css`
          @media (min-width: 744px) and (max-width: 1280px) {
            ${tw`px-[4.5rem] py-[2.5rem]`}
          }
          @media (min-width: 280px) and (max-width: 744px) {
            ${tw`px-[1rem] py-[1.25rem]`}

          }
        `]}>
            <FlexRowBetween css={[tw`max-w-medium justify-center items-center space-x-[9rem]`, css`
              @media (min-width: 280px) and (max-width: 1280px) {
                ${tw`flex-col-reverse items-start space-x-[0px] w-full`}
              }
            `]}>
                <img css={[css`
                  display: block;
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`self-center my-[1.88rem]`}
                  }
                  @media (max-width: 480px) {
                    ${tw`w-[21rem] hidden`}
                  }
                `]} src={i18n.language === 'en' ? '/images/3en.png' : '/images/fifthBanner.png'}/>
                <FlexColumnStart css={[tw`space-y-[2.69rem]`, css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`space-y-[1.25rem]`}
                  }
                `]}>
                    <FlexColumn
                        tw={'bg-primary p-4 rounded-full w-[3.125rem] h-[3.125rem] items-center justify-center'}>
                        <img
                            css={[css`
                              filter: brightness(0) invert(1);
                            `]}
                            src={'/images/voice.svg'}/>
                    </FlexColumn>
                    <StyledText size={'head'} css={[tw`font-[656] text-[2.8125rem] leading-[3.85rem]`, css`
                      @media (min-width: 280px) and (max-width: 744px) {
                        ${tw`leading-normal`}
                      }
                    `]}>
                        {t("fifth.title")}
                    </StyledText>
                    <FlexColumnStart css={[tw`space-y-[1.25rem] w-[26.625rem] font-[457]`, css`
                      @media (max-width: 744px) {
                        ${tw`w-full space-y-[0.625rem]`}
                      }
                    `]}>
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("fifth.desc")}
                        </StyledText>
                        <img css={[css`
                          display: none;
                          @media (max-width: 744px) {
                            ${tw`w-[21rem] self-center block`}
                          }
                        `]} src={i18n.language === 'en' ? '/images/3en.png' : '/images/fifthBanner.png'}/>
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("fifth.desc1")}
                        </StyledText>
                    </FlexColumnStart>
                </FlexColumnStart>
            </FlexRowBetween>
        </FlexColumn>
    );
};
