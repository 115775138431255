/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexRow, FlexRowBetween, StyledButton, StyledText} from "../GlobalComponents";
import {css} from "@emotion/react/macro";
import {UserIco} from "../../assets/UserIco";
import {useState} from "react";
import {Menu} from "../../pages/home/sections/Menu";
import {useTranslation} from "react-i18next";
import {Link} from 'react-scroll';
import {useNavigate} from "react-router";

export const Header = () => {
    const items = [
        {
            img: '/images/mobile.svg',
            text: 'header.app',
            link: 'app'
        },
        {
            imgAsset: UserIco('black'),
            text: 'header.community',
            link: 'community'
        },
        {
            img: '/images/specialists.svg',
            text: 'header.for_specialists',
            link: 'specialists'

        },
        {
            img: '/images/contacts.svg',
            text: 'header.contacts',
            link: 'contacts'
        },
    ];

    const [openMenu, setOpenMenu] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    return (
        <>

            <FlexRowBetween
                css={[
                    tw`shadow-sm z-[80] bg-white w-full fixed top-0 left-0 px-[3.12rem] items-center h-[6.25rem]`,
                    css`
                      @media (max-width: 480px) {
                        ${tw`px-[1rem] h-[3.375rem]`}
                      }
                    `,
                    // openMenu && tw`bg-pageBgGray shadow-none`
                ]}
            >
                <FlexRow
                    css={[tw`space-x-[0.85rem] items-center`]}
                >
                    <img
                        onClick={() => navigate(`/`)}
                        tw={'cursor-pointer w-[3.2rem] h-[3.2rem]'}
                        src={'/images/logo.svg'}/>
                    <StyledText
                        onClick={() => navigate(`/`)}
                        tw={'cursor-pointer bg-clip-text bg-gradient-to-r from-accent text-opacity-[0%] to-accentLight font-[556] text-[1rem]'}>TRUMENEVA</StyledText>
                </FlexRow>


                <FlexRow
                    css={[tw`space-x-[2rem]`,
                        css`
                          @media (min-width: 744px) and (max-width: 1280px) {
                            display: none;
                          }
                          @media (min-width: 280px) and (max-width: 744px) {
                            display: none;
                          }
                        `]}>
                    {items.map((it, idx) =>

                        <Link
                            onClick={() => navigate(`/`)}
                            to={it.link}
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                            tw={'flex flex-row space-x-[0.5rem] cursor-pointer'}>
                            <img src={it.img}/>
                            {it.imgAsset}
                            <StyledText size={'small'} css={[tw`w-[100%]`, css`text-wrap: nowrap`]}>{t(it.text)}</StyledText>
                        </Link>
                    )}
                </FlexRow>


                <FlexRow
                    css={[tw`space-x-[1.12rem]`, css`
                      @media (min-width: 744px) and (max-width: 1280px) {
                        display: ${openMenu ? 'none' : 'flex'};
                      }
                      @media (max-width: 480px) {
                        display: none;
                      }
                    `]}>
                    <Link to={'https://www.linkedin.com/company/trumenapp/'}>
                        <img tw={'cursor-pointer'} src={'/images/linkedin.svg'}/>
                    </Link>
                    <Link
                        to={i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi'}>
                        <img tw={'cursor-pointer'} src={'/images/tg.svg'}/>
                    </Link>
                    <Link to={'mailto:contact@trumeneva.com'}>
                        <img tw={'cursor-pointer'} src={'/images/mail.svg'}/>
                    </Link>
                </FlexRow>

                <StyledButton
                    onClick={() => setOpenMenu(!openMenu)}
                    css={[
                        tw`bg-white`,
                        css`
                          display: none;
                          @media (min-width: 280px) and (max-width: 1280px) {
                            ${tw`block p-0`}
                          }
                        `,
                        openMenu && tw`bg-pageBgGray`
                    ]}>
                    <img src={openMenu ? '/images/close.svg' : '/images/burger.svg'}/>
                </StyledButton>
            </FlexRowBetween>
            {openMenu && <Menu openMenu={openMenu} setOpenMenu={setOpenMenu}/>}
        </>

    );
};
