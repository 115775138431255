/** @jsxImportSource @emotion/react */
import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Landing} from "./Landing";
import AppProvider from "./config/AppProvider";
import {Terms} from "./pages/documents/Terms";
import {Privacy} from "./pages/documents/Privacy";
import {License} from "./pages/documents/License";
import {Security} from "./pages/documents/Security";
import {Community} from "./pages/documents/Community";
import {Ethics} from "./pages/documents/Ethics";
import {Cookie} from "./pages/documents/Cookie";


function App() {

    return (
        <AppProvider>
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<Landing/>}/>
                    <Route path={'/terms'} element={<Terms/>}/>
                    <Route path={'/privacy'} element={<Privacy/>}/>
                    <Route path={'/security'} element={<Security/>}/>
                    <Route path={'/community'} element={<Community/>}/>
                    <Route path={'/ethics'} element={<Ethics/>}/>
                    <Route path={'/cookie'} element={<Cookie/>}/>
                    <Route path={'/license'} element={<License/>}/>
                    <Route path={'/*'} element={<Navigate to={'/'} replace={true}/>}/>
                </Routes>
            </BrowserRouter>
        </AppProvider>

    );
}

export default App;
