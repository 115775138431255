import {FlexColumn, FlexColumnStart, FlexRowBetween} from "../../components/GlobalComponents";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import styled from "@emotion/styled/macro";
import tw from "twin.macro";
import {Header} from "../../components/header/Header";
import {useEffect} from "react";

const Content = styled(FlexRowBetween)`
  ${tw`p-16 pt-32 space-x-12 max-[600px]:flex-col max-[600px]:space-x-0 max-[480px]:p-4 max-[480px]:pt-16`}
`;

const LeftTextBlock = styled(FlexColumnStart)`
  ${tw`w-1/2 space-y-4 max-[600px]:w-full`}
  .text-block {
    ${tw`space-y-2`}
    h1 {
      ${tw`font-[600] text-[1.75rem]`}
    }

    p {
      ${tw`font-[400] text-[1rem] whitespace-break-spaces`}
    }
  }

  .text-table {
    table {
      ${tw`border-spacing-6 border border-[0.5px] border-[#E3E3E3] rounded-lg border-separate`}
      thead {
        tr {
          ${tw`gap-4`}
          td {
            ${tw` text-[0.75rem]`}
          }
        }
      }
    }
  }
`;

const RightTextBlock = styled(LeftTextBlock)`
`;

export const Terms = () => {
    const params = useParams();
    const lng = params['lang'];
    const {t, i18n} = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(lng);
    }, [lng]);


    const firstPart = [...Array.from(Array(12).keys()), 7.1]
        .splice(1)
        .sort((a, b) => a - b)
    const secPart = Array.from(Array(26).keys()).splice(12)

    return (
        <FlexColumn>
            <Header/>
            <Content>
                <LeftTextBlock>
                    {firstPart
                        .map((it, idx) =>
                            it === 7
                                ? <>
                                    <FlexColumn className={'text-block'}>
                                        <h1>{it}. {t(`terms.7.title`)}</h1>
                                        <p>{t("terms.7.text0")}</p>
                                    </FlexColumn>
                                    <FlexColumn className={'text-table'}>
                                        <table>
                                            <thead>
                                            {Array.from(Array(5).keys()).splice(1).map((it, idx) =>
                                                <tr>
                                                    <td>{t(`terms.7.table1.${it}.param`)}</td>
                                                    <td>{t(`terms.7.table1.${it}.value`)}</td>
                                                </tr>
                                            )}
                                            </thead>
                                        </table>
                                    </FlexColumn>
                                    <FlexColumn className={'text-block'}>
                                        <p>{t("terms.7.text1")}</p>
                                    </FlexColumn>
                                    <FlexColumn className={'text-table'}>
                                        <table>
                                            <thead>
                                            {Array.from(Array(6).keys()).splice(1).map((it, idx) =>
                                                <tr>
                                                    <td>{t(`terms.7.table2.${it}.param`)}</td>
                                                    <td>{t(`terms.7.table2.${it}.value`)}</td>
                                                </tr>
                                            )}
                                            </thead>
                                        </table>
                                    </FlexColumn>
                                </>
                                : <FlexColumn className={'text-block'}>
                                    <h1>{it}. {t(`terms.${it}.title`)}</h1>
                                    <p>{t(`terms.${it}.text`)}</p>
                                </FlexColumn>
                        )}
                </LeftTextBlock>
                <RightTextBlock>
                    {secPart
                        .map((it, idx) =>
                            <FlexColumn className={'text-block'}>
                                <h1>{it}. {t(`terms.${it}.title`)}</h1>
                                <p>{t(`terms.${it}.text`)}</p>
                            </FlexColumn>
                        )}
                </RightTextBlock>
            </Content>
        </FlexColumn>
    );
};
