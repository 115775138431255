export const ContactsIco = (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon" clip-path="url(#clip0_1911_21699)">
            <path id="Vector 1021" d="M17.5 3.5L1 10L9.5 14L22 2L14 22.5L11 16" stroke="white" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1911_21699">
                <rect width="22.5" height="22" fill="white" transform="matrix(1 0 0 -1 0.270508 22.7549)"/>
            </clipPath>
        </defs>
    </svg>

);