/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexRow, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const Footer = () => {
    const {t, i18n} = useTranslation();

    return (
        <FlexColumn
            id={'contacts'}
            css={[tw`px-[1rem] bg-pageBgWhite items-center justify-center py-[6rem] w-full`,
                css`
                  @media (max-width: 744px) {
                    ${tw`px-[1rem] py-[1.25rem]`}
                  }
                `]}>
            <FlexColumn tw={'max-w-medium justify-center items-center space-y-[1.88rem]'}
                        css={[css`max-width: 1400px;`]}>
                <FlexColumn
                    css={[
                        tw`items-center`,
                        css`
                          @media (min-width: 280px) and (max-width: 744px) {
                            ${tw`items-start`}
                          }
                        `
                    ]}>
                    <StyledText tw={'text-[1rem] text-primary font-[656]'}>
                        {t("footer.contacts")}
                    </StyledText>
                    <StyledText
                        css={[
                            tw`w-[32.75rem] text-[1rem] text-textGray font-[457] text-center`,
                            css`
                              @media (min-width: 744px) and (max-width: 1280px) {
                                ${tw`w-[23rem]`}
                              }
                              @media (min-width: 280px) and (max-width: 744px) {
                                ${tw`w-full text-start`}
                              }
                            `
                        ]}
                    >
                        {t("footer.desc")}
                    </StyledText>
                </FlexColumn>
                <FlexColumn tw={'items-center space-y-[1.25rem]'}>
                    <FlexRow tw={'space-x-[1.12rem]'}>
                        <Link to={'https://www.linkedin.com/company/trumenapp/'}>
                            <img tw={'cursor-pointer'} src={'/images/linkedin.svg'}/>
                        </Link>
                        <Link
                            to={i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi'}>
                            <img tw={'cursor-pointer'} src={'/images/tg.svg'}/>
                        </Link>
                        <Link to={'mailto:contact@trumeneva.com'}>
                            <img tw={'cursor-pointer'} src={'/images/mail.svg'}/>
                        </Link>
                    </FlexRow>
                    <StyledText
                        css={[
                            tw`font-[556] text-center`,
                            css`
                              @media (max-width: 480px) {
                                ${tw`text-[1.125rem]`}
                              }
                            `
                        ]}
                    >
                        {t("footer.reg_number")}<br/>
                        {t("footer.adress")}</StyledText>
                    <StyledText
                        css={[
                            tw`font-[556] text-center`,
                            css`
                              @media (max-width: 480px) {
                                ${tw`text-[1.125rem]`}
                              }
                            `
                        ]}>
                        {t("footer.sign")}
                    </StyledText>
                </FlexColumn>
                <StyledButton
                    onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                    css={[
                        tw`w-fit flex flex-row space-x-[0.5rem] items-center px-[1rem]`,
                        css`
                          @media (max-width: 480px) {
                            ${tw`w-full items-center justify-center`}
                          }
                        `
                    ]}>

                    <StyledText tw={'font-[556] text-[1rem] text-white'}>
                        {t("menu.join")}
                    </StyledText>
                    <img
                        css={[css`
                          filter: brightness(0) invert(1);
                        `]}
                        src={'/images/users.svg'}/>
                </StyledButton>
                <FlexRow
                    css={[
                        tw`space-x-[1.88rem]`,
                        css`
                          @media (min-width: 744px) and (max-width: 1280px) {
                            ${tw`flex-col items-center space-x-0 space-y-[0.94rem]`}
                          }
                          @media (min-width: 280px) and (max-width: 744px) {
                            ${tw`w-full flex-wrap items-center space-x-0 justify-center gap-[1rem]`}
                          }
                        `
                    ]}>
                    <Link to={'/terms'}>
                        <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                            {t("footer.terms")}
                        </StyledText>
                    </Link>
                    <Link to={'/privacy'}>
                        <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                            {t("footer.privacy")}
                        </StyledText>
                    </Link>
                    <Link to={'/security'}>
                        <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                            {t("footer.security")}
                        </StyledText>
                    </Link>
                    <Link to={'/community'}>
                        <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                            {t("footer.community")}
                        </StyledText>
                    </Link>
                    <Link to={'/ethics'}>
                      <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                        {t("footer.ethics")}
                      </StyledText>
                    </Link>
                    <Link to={'/cookie'}>
                      <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                        {t("footer.cookie")}
                      </StyledText>
                    </Link>
                    <a href={'/documents/E-CommercialLicense.pdf'}>
                        <StyledText tw={'text-primary text-[1rem] font-[656] tracking-[0.125rem]'}>
                            {t("footer.license")}
                        </StyledText>
                    </a>

                    <StyledText tw={'text-textGray text-[1rem] font-[556] tracking-[0.125rem]'}>
                        {t("footer.company")}
                    </StyledText>
                </FlexRow>
            </FlexColumn>
        </FlexColumn>
    );
};
