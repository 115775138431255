/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRow, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const FourthSection = () => {

    const items = [
        {
            ico: '/images/smile.svg',
            title: 'fourth.smile_title',
            text: `fourth.smile_desc`
        },
        {
            ico: '/images/relations.svg',
            title: 'fourth.relations_title',
            text: `fourth.relations_desc`
        },
        {
            ico: '/images/physics.svg',
            title: 'fourth.phyzics_title',
            text: `fourth.phyzics_desc`
        },
        {
            ico: '/images/hobby.svg',
            title: 'fourth.hobby_title',
            text: `fourth.hobby_desc`
        },
        {
            ico: '/images/diamond.svg',
            title: 'fourth.diamond_title',
            text: `fourth.diamond_desc`
        },
        {
            ico: '/images/plusPeople.svg',
            title: 'fourth.society_title',
            text: `fourth.society_desc`
        },

    ]

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn css={[tw`px-[1rem] w-full bg-white py-[6rem] items-center justify-center`,
            css`
              @media (min-width: 744px) and (max-width: 1280px) {
                ${tw`px-[4.5rem] py-[2.5rem]`}
              }
              @media (min-width: 280px) and (max-width: 744px) {
                ${tw`px-[1rem] py-[1.25rem]`}
              }
            `]}>
            <FlexColumn tw={'max-w-medium bg-white justify-center items-center'}>
                <FlexColumn
                    css={[tw`space-y-[0.5rem] items-center`, css`
                      @media (max-width: 480px) {
                        ${tw`items-start justify-start self-start`}
                      }
                    `]}>
                    <StyledText tw={'text-[1rem] uppercase text-primary font-[600] p-0 m-0'}>
                        {t("fourth.solutions")}
                    </StyledText>
                    <StyledText size={'head'} tw={'font-[600] p-0 m-0'}>
                        {t("fourth.popularReqs")}
                    </StyledText>
                </FlexColumn>
                <FlexRow
                    css={[tw`flex-wrap gap-[2.19rem] mt-[3.75rem] justify-center`]}>
                    {
                        items.map((it, idx) =>
                            <FlexRow
                                css={[
                                    tw`w-[35.25rem] bg-pageBgWhite rounded-[1.25rem] items-center space-x-[2.62rem] p-[1.81rem]`,
                                    css`
                                      @media (max-width: 744px) {
                                        ${tw`flex-col items-baseline space-x-[0rem] w-full p-[0.62rem]`}
                                      }
                                    `]}>
                                <img
                                    css={[css`
                                      @media (max-width: 480px) {
                                        ${tw`w-[2.875rem] h-[2.875rem]`}
                                      }
                                    `, tw`w-[4.8125rem] h-[4.8125rem]`]}
                                    src={it.ico}/>
                                <FlexColumnStart>
                                    <StyledText tw={'font-[656]'}>{t(it.title)}</StyledText>
                                    <StyledText tw={'text-[1rem] font-[457] text-textGray'}>
                                        {t(it.text)}
                                    </StyledText>
                                </FlexColumnStart>
                            </FlexRow>
                        )
                    }
                </FlexRow>

            </FlexColumn>
        </FlexColumn>
    );
};
