/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRow, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const ReviewsSlider = () => {

    const reviews = [
        {
            name: 'Dr. Nina Savelle-Rocklin',
            img: 'images/face_slide1.svg',
            work: 'reviews.first_prof',
            link: 'linkedin',
            review: 'reviews.first'
        },
        {
            name: 'reviews.anonim',
            img: 'images/face2.svg',
            work: 'reviews.focusGroup',
            review: 'reviews.second'
        },
        {
            name: 'reviews.anonim',
            img: 'images/face3.svg',
            work: 'reviews.focusGroup',
            review: 'reviews.third'
        },
        {
            name: 'reviews.anonim',
            img: 'images/face4.svg',
            work: 'reviews.focusGroup',
            review: 'reviews.fourth'
        },
    ];

    const [activeId, setActiveId] = useState(Math.round(reviews.length / 2));
    const sliderRef = useRef<any>(null);

    const scrollingReviews = (scrollOffset: any) => {
        sliderRef.current.scrollX += scrollOffset;
    };

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn
            css={[
                tw`items-center space-y-[2rem] relative bg-pageBgWhite w-full`,
                css`
                  @media (max-width: 480px) {
                    ${tw`items-start py-[1.25rem]`}
                  }
                `
            ]}>
            <StyledText size={'head'} css={[tw`text-[2.8125rem] leading-[3.85rem] font-[656]`, css`
              @media (max-width: 480px) {
                ${tw`px-[1rem]`}
              }
            `]}>
                {t("reviews.title")}
            </StyledText>

            <FlexRow
                ref={sliderRef}
                css={[
                    tw`relative bg-pageBgWhite space-x-[1.88rem] w-full overflow-auto`,
                    css`
                      @media (min-width: 280px) and (max-width: 1280px) {
                        ${tw`space-x-[0.62rem]`}
                      }
                    `
                ]}>
                <img
                    onClick={() => scrollingReviews(-100)}
                    css={[tw`cursor-pointer hover:opacity-[0.5] left-[2.5rem] top-1/2 pb-[1.1875rem] sticky rounded-full w-fit h-fit p-[1.1875rem] bg-primary items-center justify-center bg-opacity-[0.5]`,
                        css`
                          @media (max-width: 480px) {
                            ${tw`hidden`}
                          }
                        `]}
                    src={'/images/shevron.svg'}/>
                {reviews.map((it, idx) =>
                    <FlexColumn
                        key={idx}
                        css={[tw`min-w-[42rem] space-y-[1.23rem] bg-white p-[2rem] rounded-[1.875rem]`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`min-w-[20.5rem] p-[0.62rem] h-fit space-y-[0.62rem]`}
                              }`]}>
                        <FlexRow css={[
                            tw`space-x-[1.5rem]`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`space-x-[0.62rem]`}
                              }
                            `]}>
                            <img tw={'w-[5rem] h-[5rem] rounded-full'} src={it.img}/>
                            <FlexColumnStart>
                                <StyledText
                                    css={[tw`font-[556]`,
                                        css`@media (min-width: 280px) and (max-width: 1280px) {
                                          ${tw`text-[1.25rem]`}
                                        }`]}>
                                    {t(it.name)}
                                </StyledText>
                                <StyledText tw={'font-[457] text-[1rem] text-[#BBB]'}>{t(it.work)}</StyledText>
                                <StyledText tw={'font-[457] text-[1rem] text-primary'}>{it.link}</StyledText>
                            </FlexColumnStart>
                        </FlexRow>
                        <StyledText size={'small'} tw={'font-[457] text-[1rem] leading-[1.5rem]'}>
                            {t(it.review)}
                        </StyledText>
                    </FlexColumn>
                )}
                <img
                    css={[tw`rotate-180 cursor-pointer hover:opacity-[0.5] right-[2.5rem] top-1/2 pb-[1.1875rem] sticky rounded-full w-fit h-fit p-[1.1875rem] bg-primary items-center justify-center bg-opacity-[0.5]`,
                        css`
                          @media (max-width: 480px) {
                            ${tw`hidden`}
                          }
                        `]}
                    onClick={() => scrollingReviews(100)}
                    src={'/images/shevron.svg'}/>
            </FlexRow>
        </FlexColumn>

    );
};
