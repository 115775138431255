/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRow, FlexRowBetween, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const Banner = () => {

    const items = [
        {
            ico: '/images/users.svg',
            title: "banner.com_title",
            text: `banner.communication`
        },
        {
            ico: '/images/specialists.svg',
            title: "banner.exp_title",
            text: `banner.expert`
        },
        {
            ico: '/images/circles.svg',
            title: "banner.imp_title",
            text: `banner.improvement`
        },
    ];

    const {t, i18n} = useTranslation();

    return (
        <FlexRowBetween
            css={[tw`bg-gradient-to-r items-baseline from-accent to-accentLight p-[7rem]`, css`
              @media (min-width: 280px) and (max-width: 1280px) {
                ${tw`flex-col space-y-[1.88rem] px-[1rem] py-[1.88rem] items-center`}
              }
            `]}>
            {items.map((it, idx) =>
                <FlexRow
                    css={[tw`space-x-[1rem]`, css`
                      @media (max-width: 1440px) {
                        ${tw`w-auto flex-col space-x-0 space-y-[1rem] items-start`}
                      }
                    `]}>
                    <FlexColumn
                        css={[tw`bg-primary p-4 rounded-full w-[3.125rem] h-[3.125rem] items-center justify-center`,
                            css`
                              @media (max-width: 1440px) {
                                ${tw`ml-0`}
                              }
                            `
                        ]}>
                        <img
                            css={[css`
                              filter: brightness(0) invert(1);
                            `]}
                            src={it.ico}/>
                    </FlexColumn>

                    <FlexColumnStart css={[tw`max-w-[20rem]`, css`
                      @media (min-width: 744px) and (max-width: 1280px) {
                        ${tw`max-w-[25rem]`}
                      }
                    `]}>
                        <StyledText tw={'font-[656] text-white'}>{t(it.title)}</StyledText>
                        <StyledText tw={'font-[457] text-[1rem] text-white'}>
                            {t(it.text)}
                        </StyledText>
                    </FlexColumnStart>
                </FlexRow>
            )}
        </FlexRowBetween>
    );
};
