export const UserIco = (color?: string) => (
    <svg width="30" height="29" viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.9821 16.1773C8.33296 15.7574 2.91268 16.2368 3.04947 19.0738C3.1613 21.393 5.86053 22.2921 9.84904 22.1129"
            stroke={color || "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M14.0864 4.71887C13.4004 4.21044 12.5615 3.87793 11.6363 3.79811C9.04047 3.57417 6.74759 5.42395 6.51565 7.93025C6.27573 10.4273 8.17794 12.64 10.7632 12.8715L10.7963 12.8744C11.3237 12.9199 11.8386 12.8797 12.3262 12.7659"
            stroke={color || "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.9457 17.8433C15.9744 17.8433 12.583 18.423 12.583 20.7448C12.583 23.0666 15.9529 23.6671 19.9457 23.6671C23.9171 23.6671 27.3075 23.0864 27.3075 20.7655C27.3075 18.4447 23.9386 17.8433 19.9457 17.8433Z"
              stroke={color || "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.946 14.5319C22.5522 14.5319 24.6645 12.4915 24.6645 9.97518C24.6645 7.4589 22.5522 5.41943 19.946 5.41943C17.3399 5.41943 15.2266 7.4589 15.2266 9.97518C15.2178 12.483 17.3164 14.5234 19.9128 14.5319H19.946Z"
              stroke={color || "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);