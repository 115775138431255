/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const EithSection = () => {

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn css={[tw`bg-pageBgWhite items-center justify-center py-[6rem] w-full`,
            css`
              @media (min-width: 744px) and (max-width: 1280px) {
                ${tw`px-[4.5rem] py-[1.88rem]`}
              }
              @media (min-width: 280px) and (max-width: 744px) {
                ${tw`px-[1rem] py-[1.25rem]`}
              }
            `
        ]}>
            <FlexRowBetween
                css={[
                    tw`bg-white justify-between rounded-[2.375rem] max-w-medium items-center`,
                    css`
                      @media (min-width: 280px) and (max-width: 1280px) {
                        ${tw`flex-col space-x-0`}
                      }
                    `
                ]}
            >
                <FlexColumnStart
                    css={[
                        tw`pl-[6.56rem] w-1/2`,
                        css`
                          @media (min-width: 280px) and (max-width: 1280px) {
                            ${tw`w-full p-[1.88rem]`}
                          }
                        `
                    ]}
                >
                    <img src={'images/quotes.svg'}/>
                    <StyledText
                        css={[
                            tw`font-[656] pt-[2.69rem] pb-[5.69rem] order-1`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`pb-[1.88rem]`}
                              }
                              @media (max-width: 480px) {
                                ${tw`pb-[0.88rem]`}
                              }
                            `]}
                    >
                        {t("eith.desc")}
                    </StyledText>
                    <StyledText
                        css={[tw`font-[350] order-2`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`hidden`}
                              }
                            `
                        ]}
                        size={'small'}>
                        {t("eith.name")}
                        <br/>
                        —— <span tw={'font-[350] text-[1rem] italic'}>
                        {t("eith.prof")}
                    </span>
                    </StyledText>
                </FlexColumnStart>
                <FlexColumnStart tw={'h-full'}>
                    <img
                        css={[tw`w-auto h-full object-cover object-top rounded-[2.375rem]`,
                            css`
                              @media (min-width: 744px) and (max-width: 1280px) {
                                ${tw`w-[33.5rem] h-[25.125rem]`}
                              }
                              @media (max-width: 744px) {
                                ${tw`h-[15.375rem] w-[18.5rem] object-cover object-top`}
                              }
                            `
                        ]} src={'/images/eithPng.png'}/>

                    <StyledText
                        css={[tw`font-[350] hidden`,
                            css`
                              @media (min-width: 280px) and (max-width: 1280px) {
                                ${tw`block mt-[0.62rem] mb-[2.60rem]`}
                              }
                            `
                        ]}
                        size={'small'}>
                        {t("eith.name")}
                        <br/>
                        —— <span tw={'font-[350] text-[1rem] italic'}>
                        {t("eith.prof")}
                    </span>
                    </StyledText>
                </FlexColumnStart>

            </FlexRowBetween>
        </FlexColumn>
    );
};
