/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {css} from "@emotion/react/macro";
import {
    FlexColumn,
    FlexColumnStart,
    FlexRow,
    FlexRowBetween,
    StyledButton,
    StyledText
} from "../../../components/GlobalComponents";
import {UserIco} from "../../../assets/UserIco";
import {MobileIco} from "../../../assets/MobileIco";
import {SpecialistsIco} from "../../../assets/SpecialistsIco";
import {ContactsIco} from "../../../assets/ContactsIco";
import {ChatIco} from "../../../assets/ChatIco";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link} from 'react-scroll';

export const Menu: FC<{ openMenu: any, setOpenMenu: any }> = (props) => {

    const {openMenu, setOpenMenu} = props;

    const items = [
        {
            imgAsset: MobileIco,
            text: 'header.app',
            link: 'app'
        },
        {
            imgAsset: UserIco('white'),
            text: 'header.community',
            link: 'community'
        },
        {
            imgAsset: SpecialistsIco,
            text: 'header.for_specialists',
            link: 'specialists'
        },
        {
            imgAsset: ContactsIco,
            text: 'header.contacts',
            link: 'contacts'
        },
    ];

    const {t, i18n} = useTranslation();

    return (
        <FlexColumnStart
            css={[tw`px-[1rem] bg-pageBgGray justify-between z-[90] fixed top-0 left-0 w-full h-full`, css`
              @media (min-width: 1280px) {
                display: none;
              }

              @media (min-width: 744px) and (max-width: 1280px) {
                ${tw`w-1/2 right-0 left-auto`}
              }

            `]}>
            <StyledButton
                onClick={() => setOpenMenu(!openMenu)}
                css={[
                    tw`bg-white self-end absolute top-0 right-0`,
                    css`
                      display: none;
                      @media (min-width: 280px) and (max-width: 1280px) {
                        ${tw`block`}
                      }
                    `,
                    openMenu && tw`bg-pageBgGray`
                ]}>
                <img src={openMenu ? '/images/close.svg' : '/images/burger.svg'}/>
            </StyledButton>

            <FlexColumnStart css={[tw`w-full pt-[6rem] space-y-[3rem]`, css`
              @media (max-width: 375px) {
                ${tw`pt-[6rem] space-y-[1.25rem]`}
              }
            `]}>
                {items.map((it, idx) =>
                    <FlexRowBetween css={[tw`w-full`]}>
                        <Link
                            onClick={() => setOpenMenu(false)}
                            to={it.link}
                            spy={true}
                            smooth={true}
                            offset={50}
                            duration={500}
                            tw={'flex flex-row w-full space-x-[0.5rem] cursor-pointer items-center'}>
                            <FlexColumn
                                tw={'text-white bg-primary w-[3.125rem] h-[3.125rem] rounded-full p-4 items-center justify-center'}>
                                {it.imgAsset}
                            </FlexColumn>

                            <StyledText size={'small'} tw={'font-[556]'}>{t(it.text)}</StyledText>
                        </Link>
                        {idx === 3 &&
                            <FlexRow tw={'space-x-[1.12rem] items-center'}>
                                <Link to={'https://www.linkedin.com/company/trumenapp/'}>
                                    <img tw={'cursor-pointer'} width={60} height={60} src={'/images/linkedin.svg'}/>
                                </Link>
                                <Link
                                    to={i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi'}>
                                    <img tw={'cursor-pointer'} width={60} height={60} src={'/images/tg.svg'}/>
                                </Link>
                                <Link to={'mailto:contact@trumeneva.com'}>
                                    <img tw={'cursor-pointer'} width={60} height={60} src={'/images/mail.svg'}/>
                                </Link>
                            </FlexRow>
                        }
                    </FlexRowBetween>
                )}
            </FlexColumnStart>

            <FlexColumn tw={'w-full space-y-[1.25rem] pb-[2.69rem]'}>
                <StyledButton
                    onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                    tw={'w-full border-primary border-2 border-solid flex flex-row justify-center items-center space-x-[0.5rem]'}>
                    <StyledText tw={'text-white text-[1rem]'}>{t("menu.join")}</StyledText>
                    {UserIco("white")}
                </StyledButton>
            </FlexColumn>
        </FlexColumnStart>
    );
};
