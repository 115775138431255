/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledButton, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

export const FirstSection = () => {


    const {t, i18n} = useTranslation();

    return (
        <FlexColumn
            css={[tw`px-[1rem] w-full items-center justify-center my-[6.38rem]`,
                css`
                  @media (min-width: 744px) and (max-width: 1280px) {
                    ${tw`my-[2.5rem] mb-[4.5rem]`}
                  }
                  @media (min-width: 280px) and (max-width: 744px) {
                    ${tw`my-[1.25rem] px-[1rem]`}
                  }
                `
            ]}>
            <FlexRowBetween
                css={[tw`max-w-large items-center space-x-[9.13rem]`, css`
                  @media (max-width: 1280px) {
                    ${tw`space-x-[5.62rem]`}
                  }
                  @media (min-width: 744px) and (max-width: 1280px) {
                    ${tw`flex-col items-start space-x-0 gap-[1.88rem]`}
                  }
                  @media (min-width: 280px) and (max-width: 744px) {
                    ${tw`flex-col items-start space-x-0 gap-[1.88rem]`}
                  }
                `]}>
                <FlexColumnStart
                    css={[tw`max-w-[30rem]`,
                        css`
                          @media (min-width: 744px) and (max-width: 1280px) {
                            ${tw`pl-[3.25rem]`}
                          }
                        `]}>
                    <StyledText
                        size={'head'}
                        css={[tw`bg-clip-text bg-gradient-to-r from-accent text-opacity-[0%] to-accentLight font-[656] text-[3.75rem]`,

                        ]}>
                        TRUMENEVA
                    </StyledText>
                    <StyledText
                        size={'big'}
                        css={[
                            tw`font-[656] leading-[3.8125rem]`,
                            css`
                              @media (max-width: 1440px) {
                                ${tw`text-[2.1875rem] leading-[2.75rem]`}
                              }
                              @media (max-width: 480px) {
                                ${tw`text-[1.875rem]`}
                              }
                            `]}>
                        {t("first.happy")}
                    </StyledText>
                    <StyledText
                        size={'medium'}
                        css={[tw`mt-[1.25rem] font-[457]`,
                            css`
                              @media (max-width: 1280px) {
                                ${tw`text-[1.375rem]`}
                              }
                            `]}>
                        {t("first.space")}
                    </StyledText>

                    <StyledButton
                        onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                        css={[
                            tw`mt-[2.5rem] px-[4.5rem]`,
                            css`
                              display: block;
                              @media (min-width: 280px) and (max-width: 1280px) {
                                display: none;
                              }`]}
                    >
                        {t("menu.join")}
                    </StyledButton>

                </FlexColumnStart>

                <FlexColumn
                    css={[tw`items-center w-[45.375rem] h-[34rem] space-y-[0.62rem] relative`, css`
                      @media (max-width: 744px) {
                        ${tw`w-full m-0`}
                      }
                    `]}>

                    <iframe
                        css={[tw`w-[45rem] h-[34rem] rounded-[2.375rem]`, css`
                          @media (max-width: 1280px) {
                            ${tw`w-[40rem] h-[30rem]`}
                          }
                          @media (max-width: 744px) {
                            ${tw`w-full h-[15.625rem]`}
                          }
                        `]}
                        width="auto"
                        height="auto"
                        src="https://www.youtube.com/embed/PaKRpDrWOfE?si=G5-QAT5fM8eLq7lR"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>

                    <img
                        css={[tw`absolute -right-10 -bottom-10`, css`
                          @media (min-width: 280px) and (max-width: 1280px) {
                            ${tw`absolute right-0 -top-10 w-[7.80706rem] h-[4.15356rem]`}
                          }
                          @media (max-width: 744px) {
                            ${tw`absolute -right-[10px] -top-[25px] w-[6.4375rem] h-[4.19838rem]`}
                          }
                        `]}
                        src={i18n.language === 'en' ? '/images/bannerEn.png' : '/images/banner_sign.svg'}/>

                    <StyledText size={'small'}>
                        {t("first.feelings")}
                    </StyledText>

                    <StyledButton
                        onClick={() => window.location.replace(i18n.language === 'ru' ? 'https://t.me/+GRE6KXcqK8djOTAy' : 'https://t.me/+_119YA0Aa0diYzdi')}
                        css={[tw`mt-[2.5rem] px-[2.5rem]`,
                            css`
                              display: none;
                              @media (min-width: 480px) and (max-width: 1280px) {
                                display: block;
                              }
                              @media (min-width: 280px) and (max-width: 480px) {
                                display: block;
                                ${tw`w-full`}
                              }`]}>
                        <StyledText size={'small'} tw={'text-white text-[0.73rem] w-full'}>
                            {t("menu.join")}
                        </StyledText>
                    </StyledButton>

                </FlexColumn>
            </FlexRowBetween>
        </FlexColumn>
    );
};
