import {Header} from "./components/header/Header";
import {FirstSection} from "./pages/home/sections/FirstSection";
import {SecondSection} from "./pages/home/sections/SecondSection";
import {Banner} from "./pages/home/sections/Banner";
import {ThirdSection} from "./pages/home/sections/ThirdSection";
import {FourthSection} from "./pages/home/sections/FourthSection";
import {FifthSection} from "./pages/home/sections/FifthSection";
import {ReviewsSlider} from "./pages/home/sections/ReviewsSlider";
import {SixthSection} from "./pages/home/sections/SixthSection";
import {SevethSection} from "./pages/home/sections/SevethSection";
import {EithSection} from "./pages/home/sections/EithSection";
import {NinethSection} from "./pages/home/sections/NinethSection";
import {FAQ} from "./pages/home/sections/FAQ";
import {Footer} from "./pages/home/sections/Footer";
import {FlexColumn} from "./components/GlobalComponents";
import React from "react";
import styled from "@emotion/styled/macro";
import tw from "twin.macro";
import AppProvider from "./config/AppProvider";

const Content = styled(FlexColumn)`
  ${tw`pt-[6.25rem]`}
`
export const Landing = () => {
    return (
        <FlexColumn tw="bg-pageBgGray w-screen h-auto min-h-screen relative">
            <Header/>
            <Content>
                <FirstSection/>
                <SecondSection/>
                <Banner/>
                <ThirdSection/>
                <FourthSection/>
                <FifthSection/>
                <ReviewsSlider/>
                <SixthSection/>
                <SevethSection/>
                <EithSection/>
                <NinethSection/>
                <FAQ/>
                <Footer/>
            </Content>
        </FlexColumn>
    );
};
