/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {FlexColumn, FlexColumnStart, FlexRowBetween, StyledText} from "../../../components/GlobalComponents";
import {css} from "@emotion/react/macro";
import {useTranslation} from "react-i18next";

export const SixthSection = () => {

    const {t, i18n} = useTranslation();

    return (
        <FlexColumn css={[tw`px-[1rem] bg-pageBgWhite items-center justify-center py-[6rem] w-full`, css`
          @media (min-width: 744px) and (max-width: 1280px) {
            ${tw`px-[4.22rem] pt-[2.5rem]`}
          }
          @media (min-width: 280px) and (max-width: 744px) {
            ${tw`px-[1rem] py-[1.25rem]`}
          }
        `]}>
            <FlexRowBetween
                css={[tw`max-w-medium justify-center  items-center space-x-[9rem]`, css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`flex-col items-start space-x-[0px] w-full`}
                  }
                `]}>
                <FlexColumnStart css={[tw`space-y-[2.69rem]`, css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`space-y-[1.25rem]`}
                  }
                `]}>
                    <FlexColumn
                        tw={'bg-primary p-4 rounded-full w-[3.125rem] h-[3.125rem] items-center justify-center'}>
                        <img
                            css={[css`
                              filter: brightness(0) invert(1);
                            `]}
                            src={'/images/ai.svg'}/>
                    </FlexColumn>
                    <StyledText size={'head'} tw={'font-[656] text-[2.8125rem] leading-[3.85rem]'}>
                        {t("sixth.title")}
                    </StyledText>
                    <FlexColumnStart
                        css={[tw`space-y-[1.25rem] w-[26.625rem] font-[457]`, css`
                          @media (max-width: 744px) {
                            ${tw`w-full space-y-[0.625rem]`}
                          }
                        `]}>
                        <StyledText size={'small'} tw={'text-textGray'}>
                            {t("sixth.desc")}
                        </StyledText>
                    </FlexColumnStart>
                </FlexColumnStart>
                <img css={[tw``, css`
                  @media (min-width: 280px) and (max-width: 1280px) {
                    ${tw`py-[1.88rem] self-center`}
                  }
                `]} src={i18n.language === 'en' ? '/images/4en.png' : '/images/banner_six.svg'}/>
            </FlexRowBetween>
        </FlexColumn>
    );
};
