/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {css} from "@emotion/react/macro";
import styled from "@emotion/styled/macro";

export const hoverBtnStyles = css`
  &:hover {
    transition: 0.3s all ease;
    ${tw`opacity-[0.7]`}
  }
`

export const mediaBtnStyles = css`
  @media(min-width: 280px) and (max-width: 480px){
    ${tw`text-[1rem]`}
    p {
      ${tw`text-[1rem]`}
    }
  }
`

export const StyledButton = styled.button`
  ${tw`cursor-pointer bg-primary border-none text-white rounded-full p-[1rem] text-[1.25rem] font-[556]`}
  ${mediaBtnStyles}
  ${hoverBtnStyles}
`

export const FlexRow = tw.div`
  flex flex-row
`

export const FlexColumn = tw.div`
  flex flex-col
`

export const FlexRowBetween = tw.div`
  flex flex-row justify-between
`

export const FlexColumnStart = tw.div`
  flex flex-col items-start
`

export const mediaBigSize = css`
  @media (max-width: 480px) {
    ${tw`text-[2.8125rem]`}
  }
`

export const mediaHeadSize = css`
  
  @media(max-width: 744px){
    ${tw`text-[2.5rem]`}
  }
  
  @media (max-width: 480px) {
    ${tw`text-[1.875rem]`}
  }
`


export const StyledText = styled.p<{ size?: 'head' | 'big' | 'medium' | 'small' }>(({size = 'medium'}) => [
    tw`text-black`,
    size === 'small' && tw`text-[1.125rem]`,
    size === 'medium' && tw`text-[1.5rem]`,
    size === 'big' && tw`text-[3.4375rem]`,
    size === 'head' && tw`text-[4.0625rem]`,
    size === 'big' && mediaBigSize,
    size === 'head' && mediaHeadSize,

])