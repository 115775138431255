import {FC, ReactNode, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AppProvider: FC<{ children?: ReactNode }> = ({children}) => {
    const {t, i18n} = useTranslation();
    
    useEffect(() => {
        const domain = window.location.hostname
                .split(".")
                .at(1)
                ?.toString()
            ?? "com";

        i18n.changeLanguage(
            {
                "ru": 'ru',
                'com': 'en'
            }[domain]
        );
        console.log(domain)

    }, []);
    document.body.style.overflowX = "hidden";
    return (
        <>{children}</>
    );
};

export default AppProvider;
